import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentsIcon from '@mui/icons-material/Payments';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box pt={10} pb={5} px={8} className="footer seamless-pattern-foreground" >
            <Box className="divider-pattern-foregound" mt={3} mb={8 }  />

            <Grid container justifyContent="space-evenly" alignItems="center" spacing={3}>
                <Grid sx={{ minWidth: "250px;" }} item xs={12} sm={4}  container alignItems="center">
                    <Box className="logo-footer"/>
                </Grid>
                <Grid xs={12} sm={8} item container xs={12} sm={8} spacing={3} justifyContent="flex-end" >
                    <Grid item container xs={12} sm={6} direction="column" alignItems="flex-start" spacing={2} >
                        <Box component={Grid} item container >
                            <Box component={Grid} item component={LocationOnIcon} mr={2} />
                            <Box component={Grid} item style={{ fontWeight: "bold" }} mr={1}>
                                {t("address")}:
                            </Box>
                            <Box component={Grid} item>
                                <a style={{ color: "white" }} href="https://maps.app.goo.gl/npf69VtexGB4QGiz8">
                                    ul. Bolesława Śmiałego 18/2 70-347 Szczecin
                                </a>
                            </Box>
                        </Box>
                        <Box component={Grid} item container >
                            <Box component={Grid} item component={EmailIcon} mr={2} />
                            <Box component={Grid} item style={{ fontWeight: "bold" }} mr={1}>
                                {t("email")}:
                            </Box>
                            <Box component={Grid} item >
                                <a style={{ color: "white" }} href="mailto: kancelaria@urban-malada-adwokat.pl">kancelaria@urban-malada-adwokat.pl</a>
                            </Box>
                        </Box>
                        <Box component={Grid} item container >
                            <Box component={Grid} item component={LocalPhoneIcon} mr={2} />
                            <Box component={Grid} item style={{ fontWeight: "bold" }} mr={1}>
                                {t("telephone")}:
                            </Box>
                            <Box component={Grid} item >
                                <a style={{ color: "white" }} href="tel:+48606693009">(+48) 606 693 009</a>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} sm={6} direction="column" alignItems="flex-start" spacing={2} >
                        <Box component={Grid} item>
                            <Box component={StoreIcon} mr={2} />
                            <span style={{ fontWeight: "bold" }}>NIP:  </span> 852 14 22 222
                        </Box>
                        <Box component={Grid} item>
                            <Box component={BusinessIcon} mr={2} />
                            <span style={{ fontWeight: "bold" }}>REGON:  </span> 526 138 205
                        </Box>
                        <Box component={Grid} item>
                            <Box component={PaymentsIcon} mr={2} />
                            <span style={{ fontWeight: "bold" }}>{t("payments")}:   </span>ING BANK ŚLĄSKI SA PL 13 1050 1559 1000 0090 8264 7786
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer
