import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FamilyCourtImage from '../assets/FamilyCourtImage.webp';
import Temida from '../assets/bg5.webp';
import Court from '../assets/bg3.webp';

import Footer from './Footer';

import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';


const Payments = () => {
    const { t } = useTranslation();

    return (<>

        <Box className="third-background" sx={{ zIndex: 0 }}>
            <Box mx={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 10, md: 20 }} mb={12} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container appear-1">
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={8}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box className="Playfair Display SC" sx={{ textAlign: "center" }} mb={-6}>
                                {t("payments_title")}
                            </Box>
                        </Typography>
                        <Box className="divider-dark-pattern-foregound" sx={{ color: "grey" }} />
                    </Grid>
                    <Grid item container spacing={4}>
                        <Grid item xs={12} sm={4} >
                            <Box className="section-4-splash" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">
                                <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                    {t("payments_subtitle_1")}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container px={{ xs: 2, sm: 5, md: 15 }} spacing={8} mb={6}  justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} mb={6}>
                    <Box p={4} className="translucent-container">
                        <Grid container alignItems="center" justifyContent="center">
                            <Box className="circle-avatar-container-sm">
                                <FactCheckIcon sx={{ width: "70px", height: "70px;" }} />
                            </Box>
                        </Grid>
                        <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={2}>
                            <Grid item >
                                <Typography variant="h5">
                                    <Box className="document-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("lump_sum_title")}
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                        {t("lump_sum_description")}
                        </Box>
                                </Typography>
                            </Grid>
                            <Box mb={4} />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} mb={6}>
                    <Box p={4} className="translucent-container">
                        <Grid container alignItems="center" justifyContent="center">
                            <Box className="circle-avatar-container-sm">
                                <CalendarMonthIcon sx={{ width: "70px", height: "70px;" }} />
                            </Box>
                        </Grid>
                        <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={2}>
                            <Grid item >
                                <Typography variant="h5">
                                    <Box className="document-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("lump_sum_month_title")}
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                        {t("lump_sum_month_description")}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Box mb={4} />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} mb={6}>
                    <Box p={4} className="translucent-container">
                        <Grid container alignItems="center" justifyContent="center">
                            <Box className="circle-avatar-container-sm">
                                <WorkHistoryIcon sx={{ width: "70px", height: "70px;" }} />
                            </Box>
                        </Grid>
                        <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={2}>
                            <Grid item >
                                <Typography variant="h5">
                                    <Box className="document-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("hourly_title")}
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                        {t("hourly_description")}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Box mb={4} />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} mb={6}>
                    <Box p={4} className="translucent-container">
                        <Grid container alignItems="center" justifyContent="center">
                            <Box className="circle-avatar-container-sm">
                                <GavelIcon sx={{ width: "70px", height: "70px;" }} />
                            </Box>
                        </Grid>
                        <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={2}>
                            <Grid item >
                                <Typography variant="h5">
                                    <Box className="document-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("result_title")}
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                        {t("result_description")}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Box mb={4} />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} mb={6}>
                    <Box p={4} className="translucent-container">
                        <Grid container alignItems="center" justifyContent="center">
                            <Box className="circle-avatar-container-sm">
                                <InfoIcon sx={{ width: "70px", height: "70px;" }} />
                            </Box>
                        </Grid>
                        <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={2}>
                            <Grid item>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>

                                        {t("payments_subtitle_2")}
                                        {t("payments_subtitle_3")} ING Bank Śląski SA:
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid mt={1} item>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                        <b>13 1050 1559 1000 0090 8264 7786</b> 
                                    </Box>
                                </Typography>
                            </Grid>
                            <Box mb={4} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Payments
