import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import NavMenu from './NavMenu';



const Layout = ({children}) => {

    return (
        <div>
            <NavMenu/>
            <Container disableGutters maxWidth={false} tag="main">
                {children}
            </Container>
        </div>
    );
}

export default Layout