import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';

import ImageGallery from "react-image-gallery";

import Image1 from '../assets/office_1.webp'
import Image2 from '../assets/office_2.webp'
import Image3 from '../assets/office_3.webp'

import Footer from './Footer';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const images = [
    {
        original: Image1,
        thumbnail: Image1,
    },
    {
        original: Image2,
        thumbnail: Image2,
    },
    {
        original: Image3,
        thumbnail: Image3,
    },
];




const Gallery = () => {
    const { t } = useTranslation();

    return (<>

        <Box className="second-background" sx={{ zIndex: 0 }}>
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 10, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container">
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={8}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box className="Playfair Display SC" sx={{ textAlign: "center" }} mb={-6}>
                                {t("gallery_title")}
                            </Box>
                        </Typography>
                        <Box className="divider-dark-pattern-foregound" sx={{ color: "grey" }} />
                    </Grid>
                    <Grid item>
                        <Box>
                            <ImageGallery items={images} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Gallery
