import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollToTop from './scrollToTop';

// import i18n (needs to be bundled ;)) 
import './i18n';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
        <Layout>
            <ParallaxProvider>
                <ScrollToTop/>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
                </Routes>
            </ParallaxProvider>

      </Layout>
    );
  }
}
