import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Link } from "react-router-dom";
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

import { ParallaxBanner, Parallax } from 'react-scroll-parallax';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import Letter from './Letter';
import Footer from './Footer';
import Cards from './Cards';

import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';

const Home = () => {
    const { t } = useTranslation();

    const [currentCount, setCurrentCount] = useState(0)
    const incrementCounter = () => {
        setCurrentCount(currentCount + 1);
    }




    return (<>

        <Box className="parallax-container ">
            <Box className="parallax-layer-3" sx={{ position: "relative", overflow: "hidden" }} >
                <Box id="foglayer_01" class="fog">
                    <div class="image01"></div>
                    <div class="image02"></div>
                </Box>
            </Box>
            <Box className="parallax-layer-2" ></Box>
            <Box className="parallax-layer-1"></Box>
            <Box className="parallax-layer-4">
                <Grid className="appear-1" container spacing={3} justifyContent="center" alignItems="center" direction="column" component={Box} mt={23} style={{ color: "white" }}>
                    <Grid item >
                        <Typography variant="h2" align="center">
                            <Box className="Playfair Display SC dark-shadow">
                                {t("advocate_office")}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box  class="lawdivider-container">
                            <div class="lawdivider"></div>
                            <div className='lawdivider-dot'>
                                <div className="lawdivider-paragraph">§</div>
                            </div>
                        </Box>
                    </Grid>

                    <Grid item container justifyContent="center">
                        <Grid item component={Typography} variant="h5" align="center"  >
                            <Box className="Playfair Display SC dark-shadow" >
                                Adw. Patrycja Urban-Malada
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div class="chevron-container">
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className="blur-section-borders" />
        <Box className="gradient-section" >
            <Box className="fingerprint-pattern-foreground">
                <Box p={{ xs: 4, sm: 6, md: 12 }} sx={{ textAlign: 'center', color: "white" }}>
                    <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={4}>
                        <Grid item>
                            <Typography variant="h4">
                                <Box className="Playfair Display SC" mb={-2}>
                                    {t("services_range")}
                                </Box>
                            </Typography>
                            <Box className="divider-pattern-foregound" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <Box className="bodycustomtext" mt={3}>
                                    {t("home_subtitle_1")}
                                </Box>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant="body1">
                                <Box className="bodycustomtext">
                                    {t("home_subtitle_2")}
                                </Box>
                            </Typography>
                        </Grid>
                        <Cards />
                        <Grid mt={10} xs={12} item container justifyContent="center">
                            <Box p={4} className="translucent-container" style={{ width: "100%", zIndex: 0, minHeight: "332px", position: "relative" }}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Box className="circle-avatar-container-sm">
                                        <Box className="bouncing-icon-container" ml={-2} mt={0.5}>
                                            <WorkIcon sx={{ width: "60px", height: "60px;", color: "#1f1600", opacity: "0.8" }} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid mt={2} px={{ xs: 2, sm: 4, md: 6 }} className="white-shadow" container direction="column" alignItems="center" justifyContent="center" spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1">
                                            <Box className="bodycustomtextonplex white-shadow" sx={{ textAlign: "center", color: "#1f1600", textShadow: "2px -1px 12px rgba(255,255,255,1)" }}>
                                                {t("home_subtitle_3")}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className="custombutton">
                                            <Link to="services" style={{ textAlign: "center", fontWight: "900", color: "white", textDecoration: "none" }}>
                                                <Box px={2} py={1} className="bodycustomtextonplex animate-on-hover white-shadow" sx={{  borderRadius: "10px", boxShadow: "8px 8px 24px 0px rgba(66, 68, 90, 1)", color: "#1f1600", textShadow: "2px -1px 12px rgba(255,255,255,1)", backdropFilter: "blur(10px) brightness(65%)" }}>
                                                    
                                                        {t("services")}
                                                    
                                                </Box>
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">
                                            <Box p={1} className="bodycustomtextonplex white-shadow" sx={{ borderRadius: "15px", color: "#1f1600", textShadow: "2px -1px 12px rgba(255,255,255,1)", backdropFilter: "blur(10px) brightness(85%)" }}>
                                                {t("home_subtitle_4")}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box className="side-profile" ml={-4} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
        <Box className="blur-section-borders" />
        <Box className="second-background" sx={{ overflowX: "hidden" }} >
            <Box mb={{ xs: 14, sm: 10, md: 8 }} />
            <Letter />
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Home
