import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import FamilyCourtImage from '../assets/FamilyCourtImage.webp';
import Temida from '../assets/bg5.webp';
import Court from '../assets/bg3.webp';

import Footer from './Footer';

import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    const { section } = useParams();

    useEffect(() => {
        console.log("fired");
        console.log(section);

        if (section) {
            console.log(section);
            handleScrollToSection(section);
        }
    }, [section]);

    const handleScrollToSection = (section) => {
        var element = document.getElementById('section-1');
        if (section === "family") {
            element = document.getElementById('section-2');
        }
        if (section === "civil") {
            element = document.getElementById('section-3');
        }

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (<>

        <Box className="second-background" sx={{ zIndex: 0 }}>
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 10, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container appear-1">
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={8}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box className="Playfair Display SC" sx={{ textAlign: "center" }} mb={-6}>
                                {t("services_title")}
                            </Box>
                        </Typography>
                        <Box className="divider-dark-pattern-foregound" sx={{ color: "grey" }} />
                    </Grid>
                    <Grid item container spacing={4} id="section-1">
                        <Grid item xs={12} sm={4} >
                            <Box className="section-1-splash" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box mb={3}>
                                <Typography variant="h5">
                                    <Box className="handcuffs-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("criminal_law")}
                                    </Box>
                                </Typography>
                            </Box>
                            <Typography variant="body1">
                                <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                    {t("criminal_law_description")}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} id="section-2">
                        <Grid item xs={12} sm={8}>
                            <Box mb={3}>
                                <Typography variant="h5">
                                    <Box className="family-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("family_law")}
                                    </Box>
                                </Typography>
                            </Box>
                            <Typography variant="body1">
                                <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                    {t("family_law_description")}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Box className="section-2-splash" />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} id="section-3">
                        <Grid item xs={12} sm={4} >
                            <Box className="section-3-splash" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box mb={3}>
                                <Typography variant="h5">
                                    <Box className="insured-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("civil_law")}
                                    </Box>
                                </Typography>
                            </Box>
                            <Typography variant="body1">
                                <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                    {t("civil_law_description")}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />

        <Box className="third-background" >
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 16, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container">
                <Grid container alignItems="center" justifyContent="center">
                    <Box className="circle-avatar-container" mt={{ xs: -14, sm: -16, md: -20 }}>
                        <Box ml={0.8} className="suitcase"></Box>
                    </Box>
                </Grid>
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={{ xs: 8, sm: 5, md: 5 }} spacing={2}>
                    <Grid item >
                        <Typography variant="body1">
                            <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                {t("services_subtitle_1")}

                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Grid container>
                                <Grid item mr={1}><MedicalInformationIcon /></Grid>
                                <Grid item mt={0.2}>
                                    <Box fontWeight='800' fontSize= '20px' lineHeight='28px'>
                                        {t("medical_issues")}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Grid container>
                                <Grid item mr={1}><EngineeringIcon /></Grid>
                                <Grid item mt={0.2}>
                                    <Box fontWeight='800' fontSize='20px' lineHeight='28px'>
                                        {t("job_issues")}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Grid container>
                                <Grid item mr={1}><Diversity1Icon /></Grid>
                                <Grid item mt={0.2}>
                                    <Box fontWeight='800' fontSize='20px' lineHeight='28px'>
                                        {t("mobbing_issues")}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                {t("services_subtitle_2")}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Box className="fourth-background" >
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 16, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container">
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
                    <Box className="circle-avatar-container" mt={{ xs: -14, sm: -16, md: -20 }} ml={3}>
                        <Box className="child"></Box>
                    </Box>
                    <Grid item className="white-shadow" mt={4} container direction="column" alignItems="center" justifyContent="center">
                        <Typography variant="body1">
                            <Box className="bodycustomtextonplex" sx={{ textAlign: "justify" }}>
                                {t("services_subtitle_3")}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" alignItems="center" justifyContent="center" >
                        <Box my={2}>
                            <Grid container direction="column" textAlign="center" justifyContent="center" spacing={4}>
                                <Grid item><VolunteerActivismIcon className="glowing-text" sx={{ fontSize: 50}} /></Grid>
                                <Grid item mt={0.2}>
                                    <Box fontWeight='800' fontSize='20px' lineHeight='28px'>
                                        {t("services_subtitle_3_bold")}
                                    </Box>
                                </Grid>
                              
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Services
