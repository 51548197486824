import React, {  useRef, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Letter = () => {
    const { t } = useTranslation();
    const greekCircleRef = useRef();


    useEffect(() => {
        window.addEventListener('scroll', event => {
            requestAnimationFrame(() => {
                const rotation = window.scrollY / 15;
                greekCircleRef.current.style.transform = `rotate(${rotation}deg)`;
            });
        });
    }, []);
    return (
        <Box m={{ xs: 2, sm: 5, md: 13 }} p={{ xs: 4, sm: 6, md: 8 }} className="translucent-container">
            <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" >
                <Grid item container justifyContent="flex-end">
                    <Box className="profile-image1" mt={{ xs: -15, sm: -17, md: -18 }} mr={{ xs: -4, sm: -6, md: -12 }}>
                        <Box className="greek-circle-pattern-foreground" ref={greekCircleRef }/>
                    </Box>
                </Grid>
                <Grid item mt={{ xs: 4, sm: 2, md: 1 }} mb={6}>
                    <Typography variant="h4">
                        <Box className="lettercustomtitle" >
                            {t("letter_title")}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        <Box className="lettercustomtext" >
                            {t("letter_description_1")} {t("letter_description_2")}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        <Box className="lettercustomtext">
                            {t("letter_description_3")} {t("letter_description_4")}
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="white-shadow" container direction="column" alignItems="flex-end" justifyContent="flex-end" pt={4} spacing={4}>
                <Grid item>
                    <Typography variant="body1">
                        <Box className="lettercustomtext" >
                            {t("advocate")}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6">
                        <Box className="signaturecustomtext" >
                            Patrycja Urban - Malada
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Letter
