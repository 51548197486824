import React, { useState, useEffect } from 'react';
import { Grid, Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';

import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import CollectionsIcon from '@mui/icons-material/Collections';
import WorkIcon from '@mui/icons-material/Work';
import PaymentsIcon from '@mui/icons-material/Payments';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    width: '80%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(15px)',
    border: '2px solid gray',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const pages = [
    { name: "start", icon: <HomeIcon />, url: '/' },
    { name: "services", icon: <WorkIcon />, url: '/services' },
    { name: "renumeration", icon: <PaymentsIcon />, url: '/payments' },
    { name: "contact", icon: <PermPhoneMsgIcon />, url: '/contact' }
];

const languages = ['Polski', 'English', 'Русский'];

const NavMenuButtons = () => {
    const { t } = useTranslation();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [scrolledToTop, setScrolledToTop] = useState(true);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleClose();
    }


    return (
        <Box mr={1}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="language-modal-container">
                        <Box onClick={() => handleClose() } sx={{ position: "absolute", right: 0, top: 0, marginTop: "-15px", cursor: "pointer", marginRight: "-15px", width: "31px", height: "31px", borderRadius: "50%", backgroundColor: "white" }} ><HighlightOffIcon sx={{ marginLeft: "-2px", marginTop: "-2px" }} fontSize="large"/></Box>
                        <Box className="globe-bg" ></Box>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Typography variant="h5">
                                        <Box className="Playfair Display SC">
                                            {t("language")}
                                        </Box>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs >
                                <Box sx={{ backgroundColor: "lightgrey", height: "1px", width: "200px" }} />
                            </Grid>
                            <Grid mt={4} item xs={12}>
                                <Grid container spacing={3} justifyContent="center" alignItems="center">
                                    <Grid item onClick={() => changeLanguage('pl')} >
                                        <Typography variant="body1" >
                                            <Box mb={1} sx={{ border: "1px solid grey", borderRadius: "5px", width: "80px", height: "80px" }}>
                                                <Box className="flag pl-flag" / >
                                            </Box>
                                            <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                                Polski
                                            </Box>
                                        </Typography>

                                    </Grid>
                                    <Grid item onClick={() => changeLanguage('en')} >
                                        <Typography variant="body1" >
                                            <Box mb={1} sx={{ border: "1px solid grey", borderRadius: "5px", width: "80px", height: "80px" }}>
                                                <Box className="flag en-flag" />
                                            </Box>
                                            <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                                English
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item onClick={() => changeLanguage('ru')} >
                                        <Typography variant="body1" >
                                            <Box mb={1} sx={{ border: "1px solid grey", borderRadius: "5px", width: "80px", height: "80px" }}>
                                                <Box className="flag ru-flag" />
                                            </Box>
                                            <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                                Русский
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Box>
                </Fade>
            </Modal>

            {/*Desktop */}
            <Grid sx={{ display: { xs: 'none', md: 'flex' } }}
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                {pages.map((page) => (
                    <Grid item key={page.name} onClick={handleCloseNavMenu}>
                        <Box className="navbar-menu-button" >
                            <Link style={{ textDecoration: 'none', color: "rgba(0, 0, 0, 0.54)" }} to={page.url}>
                                <Grid style={{ height: '80px', marginTop: '0px' }}
                                    spacing={0.5}
                                    container
                                    className="button-glow"
                                    direction="column" justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item  >
                                        {page.icon}
                                    </Grid> 
                                    <Grid item>
                                        {t(page.name)}
                                    </Grid>
                                </Grid>
                            </Link>

                        </Box>
                    </Grid>
                ))}
                <Divider style={{ marginLeft: "30px", marginTop: "30px", height: '50px', background: "black" }} orientation="vertical" flexItem />

                <Grid item key="language-container">
                    <Tooltip title="Język / Language / Язык">
                        <Box height="80px" mt="-3px" onClick={handleOpen} className="navbar-menu-button" >

                            <Grid
                                spacing={1}
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                className="button-glow"
                            >
                                <Grid item>
                                    <LanguageIcon />
                                </Grid>
                                <Grid item>
                                    {i18n.language === 'pl' && "PL"}
                                    {i18n.language === 'en' && "EN"}
                                    {i18n.language === 'ru' && "RU"}
                                </Grid>
                            </Grid>
                        </Box>
                    </Tooltip>

                </Grid>
            </Grid>

            {/*Mobile */}
 
            <IconButton
                sx={{ display: { md: 'none' }, mr: 1, mt: 2, backgroundColor: "rgba(66, 68, 90, 0.15)" }}
                size="large"
                aria-label="menu button"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"

            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
            >
                {pages.map((page) => (
                    <MenuItem sx={{
                        color: '#212529', fontFamily: "Playfair Display SC",
                    }}  key={page.name} onClick={() => { handleCloseNavMenu(); navigate(page.url) }}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography textAlign="center">{page.icon}</Typography>
                            </Grid>
                            <Grid item>
                                <Box style={{ background: "darkgrey", width:"1px", height: '30px' }}  />

                            </Grid>

                            <Grid item>
                                <Typography textAlign="center">{t(page.name)}</Typography>
                            </Grid>
                        </Grid>
                        
                    </MenuItem>
                ))}
                <Box my={1}  sx={{ backgroundColor:"grey", height: "1px", width: "100%" }} />
                <MenuItem key={"language"} onClick={handleOpen}>
               
                    <Box sx={{ border: "1px solid grey", borderRadius: "10px", padding: "5px", backgroundColor:"#f1efea"}}>

                        <Grid container direction="column" spacing={1}>

                            <Grid item>
                                <Typography textAlign="center"><LanguageIcon sx={{ fontSize: 30 }} /> </Typography>

                            </Grid>
                            <Grid item>
                                <Typography textAlign="center">Język / Language / Язык</Typography>

                            </Grid>
                        </Grid>
                    </Box>
                   

                </MenuItem>
            </Menu>

        </Box>
    );

}

export default NavMenuButtons;
