import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Link } from "react-router-dom";

import { ParallaxBanner, Parallax } from 'react-scroll-parallax';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import Letter from './Letter';
import Footer from './Footer';

import GroupsIcon from '@mui/icons-material/Groups';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    const [currentCount, setCurrentCount] = useState(0)
    const incrementCounter = () => {
        setCurrentCount(currentCount + 1);
    }

    const googleMapsIframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2377.2081638094937!2d14.5313066!3d53.4289862!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aa093c86c49223%3A0xfbd38b75cf78b8d3!2sBoles%C5%82awa%20%C5%9Amia%C5%82ego%2018%2C%2070-347%20Szczecin!5e0!3m2!1sen!2spl!4v1698843042118!5m2!1sen!2spl" width="100%" height="450" style="border:3;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    const RenderHTML = (props) => (<Box style={{ borderRadius: "10px", border: "2px solid white", backgroundColor: "white" }} dangerouslySetInnerHTML={{ __html: googleMapsIframe }}></Box>)


    return (<>
        <Box className="second-background" sx={{ zIndex: 0 }}>
            <Box m={{ xs: 2, sm: 5, md: 15 }} mt={{ xs: 14, sm: 10, md: 20 }} p={{ xs: 4, sm: 6, md: 12 }} className="translucent-container appear-1">
                <Grid className="white-shadow" container direction="column" alignItems="center" justifyContent="center" pt={4} spacing={8}>
                    <Grid item>
                        <Typography variant="h4">
                            <Box className="Playfair Display SC" sx={{ textAlign: "center" }} mb={-6}>
                                {t("contact_title")}
                            </Box>
                        </Typography>
                        <Box className="divider-dark-pattern-foregound" sx={{ color: "grey" }} />
                    </Grid>
                    <Grid item container container
                        spacing={5}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item xs={12} sm={4}>

                            <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="tel:+48606693009">
                                <Box className="contact-tile contact-tile-phone">
                                    <Box className="contact-tile-text">
                                        (+48) 606 693 009
                                    </Box>
                                </Box>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className="contact-tile contact-tile-mail">
                                <Box className="contact-tile-text">
                                    <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="mailto: kancelaria@urban-malada-adwokat.pl">kancelaria@urban-malada-adwokat.pl</a>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className="contact-tile contact-tile-post">
                                <Box className="contact-tile-text">
                                    <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="https://maps.app.goo.gl/npf69VtexGB4QGiz8">
                                        ul. Bolesława Śmiałego 18/2
                                    </a>
                                    <br />
                                    <a style={{ color: "white", textDecoration: "none", fontWeight: "600", fontSize: "20px" }} href="https://maps.app.goo.gl/npf69VtexGB4QGiz8">
                                        70-347 Szczecin
                                    </a>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Box className="bodycustomtextonplex" mt={3}>
                                {t("contact_subtitle_1")} <br/> <b>{t("contact_subtitle_2")}</b>
                                <br />
                                <br />

                                {t("contact_subtitle_3")}
                            </Box>
                            <Box className="bodycustomtextonplex" mt={3}>
                                {t("contact_subtitle_4")}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item container spacing={6}>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <RenderHTML />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} container direction="column" spacing={3}>
                            <Grid item >
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex">
                                        <GroupsIcon /> {t("contact_subtitle_5")}

                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item className="bodycustomtextonplex">
                                <Typography variant="body1">
                                    <Box component={Link} to="/gallery" sx={{ textAlign: "center", textDecoration: "underline" }}>
                                        {t("click_to_gallery")}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs component={Link} to="/gallery">
                                <Box className="colage-preview">
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <a href="https://www.facebook.com/people/Kancelaria-Adwokacka-adw-Patrycja-Urban-Malada/61550939200192/">
                            <Grid container>
                                <Grid item>
                                    <Box mt={2.5} mr={1} className="facebook"></Box>
                                </Grid>
                                <Grid item>
                                    <Box className="bodycustomtextonplex" mt={3}>
                                        {t("click_to_facebook")}
                                    </Box>
                                </Grid>
                            </Grid>
                        </a>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4} />
        </Box>
        <Box className="blur-section-borders" />
        <Footer />
    </>
    );
}

export default Contact
