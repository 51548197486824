import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Home from "./components/Home";
import Payments from "./components/Payments";
import Services from "./components/Services";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/contact',
        element: <Contact />
    },
    {
        path: '/gallery',
        element: <Gallery />
    },
    {
        path: '/services',
        element: <Services />
    },
    {
        path: '/services/:section',
        element: <Services />
    },
    {
        path: '/payments',
        element: <Payments />
    },
];

export default AppRoutes;
