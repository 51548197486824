import React from 'react';
import { Grid, Box, Typography, Divider, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import FamilyCourtImage from '../assets/FamilyCourtImage.webp';
import Temida from '../assets/bg5.webp';
import Court from '../assets/bg3.webp';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Cards = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Grid mt={5} item container alignItems="center" justifyContent="center" spacing={5} >
            <Grid item xs className="card-item-container" >
                <Box className="card-item" onClick={() => navigate("/services")}>
                    <Card sx={{ minWidth: 300, height: 300 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={Court}
                                alt="Rozprawa"
                            />
                            <CardContent>
                                <Typography variant="h5">
                                    <Box className="handcuffs-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("criminal_law")}
                                    </Box>
                                </Typography>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                        {t("criminal_law_short_description")}
                                    </Box>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs className="card-item-container">
                <Box className="card-item" onClick={() => navigate("/services/family")}>
                    <Card sx={{ minWidth: 300, height: 300 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={FamilyCourtImage}
                                alt="Wokanda"
                            />
                            <CardContent>
                                <Typography variant="h5">
                                    <Box className="family-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("family_law")}
                                    </Box>
                                </Typography>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                        {t("family_law_short_description")}
                                    </Box>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs className="card-item-container" >
                <Box className="card-item" onClick={() => navigate("/services/civil")}>
                    <Card sx={{ minWidth: 311, height: 300 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={Temida}
                                alt="Temida"
                            />
                            <CardContent>
                                <Typography variant="h5">
                                    <Box className="insured-bg" ></Box>
                                    <Box className="Playfair Display SC">
                                        {t("civil_law")}
                                    </Box>
                                </Typography>
                                <Typography variant="body1">
                                    <Box className="bodycustomtextonplex" sx={{ textAlign: "center" }}>
                                        {t("civil_law_short_description")}
                                    </Box>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Cards
